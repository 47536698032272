<template>
	<div style="display: flex;width: 100vw;height: 100vh;background: #FCFCFF;">
		<!-- <a-result status="403" title="到期" sub-title="很抱歉, 您的店铺已经过期.">
		    <template #extra>
		      <router-link to="/mendian">
		      <a-button type="primary">
		        返回
		      </a-button>
		      </router-link>
		    </template>
		  </a-result> -->
		<div style="flex: 1;"></div>
		<div style="margin-top: 100px;">
			<div style="display: flex;align-items: center;">
				<div>
					<img src="https://qiniu.boyanhy.com/car-loading2-data.gif" style="width: 300px;"/>
				</div>
				<div style="margin-left: 50px;">
					<div style="font-size: 20px;color: #000;">很抱歉，您的店铺已到期</div>
					<div style="font-size: 16px;color: #CDCDCD;margin-top: 20px;">如需开通，请联系客服，了解更多账户信息</div>
					<div style="display: flex;margin-top: 50px;">
						<div @click="showModal = true" style="background: #4570FF;color: #fff;padding: 7px 45px;border-radius: 20px;font-size: 16px;">续费</div>
						<div style="border: 1px solid #4570FF;color: #4570FF;padding: 7px 28px;border-radius: 20px;font-size: 16px;margin-left: 25px;"
							@click="toBack()">返回首页</div>
					</div>
				</div>
			</div>
		</div>
		<div style="flex: 1;"></div>
		<a-modal :footer='null' :visible='showModal' :closable='false' @cancel='showModal=false'>
			<div style="display: flex;justify-content: center;">
				<div style="font-size: 20px;color: #000;">请联系商务，电话13066688872</div>
			</div>
			<div style="display: flex;justify-content: flex-end;margin-top: 20px;">
				<a-button type='primary' @click="showModal=false">关闭</a-button>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showModal:false
			}
		},
		methods: {
			toBack() {
				this.$router.push("/mendian")
			}
		}
	}
</script>

<style>

</style>